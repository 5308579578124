import { defineNuxtPlugin } from '#app/nuxt'
import { LazyDropdown, LazyInputGroup, LazyInputNumber, LazyInputText, LazyPassword, LazyButton, LazyColumn, LazyDataTable, LazyCard, LazyPanel, LazyTabView, LazyTabPanel, LazyDialog, LazySidebar, LazyMenu, LazyMessage, LazyToast, LazySkeleton, LazyProgressSpinner } from '#components'
const lazyGlobalComponents = [
  ["Dropdown", LazyDropdown],
["InputGroup", LazyInputGroup],
["InputNumber", LazyInputNumber],
["InputText", LazyInputText],
["Password", LazyPassword],
["Button", LazyButton],
["Column", LazyColumn],
["DataTable", LazyDataTable],
["Card", LazyCard],
["Panel", LazyPanel],
["TabView", LazyTabView],
["TabPanel", LazyTabPanel],
["Dialog", LazyDialog],
["Sidebar", LazySidebar],
["Menu", LazyMenu],
["Message", LazyMessage],
["Toast", LazyToast],
["Skeleton", LazySkeleton],
["ProgressSpinner", LazyProgressSpinner],
  
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})
