import { default as _91token_93zJXEHegQeVMeta } from "/home/ubuntu/coinmaster-frontend/pages/change-email/[token].vue?macro=true";
import { default as _91token_93J3ykVaxOoNMeta } from "/home/ubuntu/coinmaster-frontend/pages/change-logout-password/[token].vue?macro=true";
import { default as _91token_93YE60qkUuPhMeta } from "/home/ubuntu/coinmaster-frontend/pages/change-password/[token].vue?macro=true";
import { default as _91token_93kBE1HDx4OsMeta } from "/home/ubuntu/coinmaster-frontend/pages/change-wallet/[token].vue?macro=true";
import { default as depositsYp7NylXspEMeta } from "/home/ubuntu/coinmaster-frontend/pages/deposits.vue?macro=true";
import { default as earn_45moreAsQzqApGwlMeta } from "/home/ubuntu/coinmaster-frontend/pages/earn-more.vue?macro=true";
import { default as _91code_93fm8eau0BsNMeta } from "/home/ubuntu/coinmaster-frontend/pages/email-confirmation/[code].vue?macro=true";
import { default as faq9jBXMzv4g4Meta } from "/home/ubuntu/coinmaster-frontend/pages/faq.vue?macro=true";
import { default as forgot_45passwordmRJv7niOtsMeta } from "/home/ubuntu/coinmaster-frontend/pages/forgot-password.vue?macro=true";
import { default as free_45rollsJ8PmSMzuarMeta } from "/home/ubuntu/coinmaster-frontend/pages/free-rolls.vue?macro=true";
import { default as freeM7DUmcbR0sMeta } from "/home/ubuntu/coinmaster-frontend/pages/free.vue?macro=true";
import { default as _91category_939O5vOexN5hMeta } from "/home/ubuntu/coinmaster-frontend/pages/games/[category].vue?macro=true";
import { default as _91game_939NQyuqzi6aMeta } from "/home/ubuntu/coinmaster-frontend/pages/games/[provider]/[game].vue?macro=true";
import { default as indexbLfN9g9uLyMeta } from "/home/ubuntu/coinmaster-frontend/pages/games/index.vue?macro=true";
import { default as games5lJZTEV8KPMeta } from "/home/ubuntu/coinmaster-frontend/pages/games.vue?macro=true";
import { default as gdpremGz2s8OJOMeta } from "/home/ubuntu/coinmaster-frontend/pages/gdpr.vue?macro=true";
import { default as indexB2Pk4k558QMeta } from "/home/ubuntu/coinmaster-frontend/pages/index.vue?macro=true";
import { default as loginCbPcqba93sMeta } from "/home/ubuntu/coinmaster-frontend/pages/login.vue?macro=true";
import { default as _91id_93LlsHA9WHtPMeta } from "/home/ubuntu/coinmaster-frontend/pages/messages/[id].vue?macro=true";
import { default as indexYgt1aOeB3YMeta } from "/home/ubuntu/coinmaster-frontend/pages/messages/index.vue?macro=true";
import { default as _91hash_93n5N7XB6l77Meta } from "/home/ubuntu/coinmaster-frontend/pages/promotion/[hash].vue?macro=true";
import { default as redeem_45tokensTIecFTZ31mMeta } from "/home/ubuntu/coinmaster-frontend/pages/redeem-tokens.vue?macro=true";
import { default as referral_45programhhp0dp3cqUMeta } from "/home/ubuntu/coinmaster-frontend/pages/referral-program.vue?macro=true";
import { default as settingspy2GqHyX7TMeta } from "/home/ubuntu/coinmaster-frontend/pages/settings.vue?macro=true";
import { default as statsXa0BkJAeDqMeta } from "/home/ubuntu/coinmaster-frontend/pages/stats.vue?macro=true";
import { default as withdraw_45nowOmGzW8z722Meta } from "/home/ubuntu/coinmaster-frontend/pages/withdraw-now.vue?macro=true";
import { default as withdrawalsVogH0Vb3ZSMeta } from "/home/ubuntu/coinmaster-frontend/pages/withdrawals.vue?macro=true";
export default [
  {
    name: _91token_93zJXEHegQeVMeta?.name ?? "change-email-token",
    path: _91token_93zJXEHegQeVMeta?.path ?? "/change-email/:token()",
    meta: _91token_93zJXEHegQeVMeta || {},
    alias: _91token_93zJXEHegQeVMeta?.alias || [],
    redirect: _91token_93zJXEHegQeVMeta?.redirect || undefined,
    component: () => import("/home/ubuntu/coinmaster-frontend/pages/change-email/[token].vue").then(m => m.default || m)
  },
  {
    name: _91token_93J3ykVaxOoNMeta?.name ?? "change-logout-password-token",
    path: _91token_93J3ykVaxOoNMeta?.path ?? "/change-logout-password/:token()",
    meta: _91token_93J3ykVaxOoNMeta || {},
    alias: _91token_93J3ykVaxOoNMeta?.alias || [],
    redirect: _91token_93J3ykVaxOoNMeta?.redirect || undefined,
    component: () => import("/home/ubuntu/coinmaster-frontend/pages/change-logout-password/[token].vue").then(m => m.default || m)
  },
  {
    name: _91token_93YE60qkUuPhMeta?.name ?? "change-password-token",
    path: _91token_93YE60qkUuPhMeta?.path ?? "/change-password/:token()",
    meta: _91token_93YE60qkUuPhMeta || {},
    alias: _91token_93YE60qkUuPhMeta?.alias || [],
    redirect: _91token_93YE60qkUuPhMeta?.redirect || undefined,
    component: () => import("/home/ubuntu/coinmaster-frontend/pages/change-password/[token].vue").then(m => m.default || m)
  },
  {
    name: _91token_93kBE1HDx4OsMeta?.name ?? "change-wallet-token",
    path: _91token_93kBE1HDx4OsMeta?.path ?? "/change-wallet/:token()",
    meta: _91token_93kBE1HDx4OsMeta || {},
    alias: _91token_93kBE1HDx4OsMeta?.alias || [],
    redirect: _91token_93kBE1HDx4OsMeta?.redirect || undefined,
    component: () => import("/home/ubuntu/coinmaster-frontend/pages/change-wallet/[token].vue").then(m => m.default || m)
  },
  {
    name: depositsYp7NylXspEMeta?.name ?? "deposits",
    path: depositsYp7NylXspEMeta?.path ?? "/deposits",
    meta: depositsYp7NylXspEMeta || {},
    alias: depositsYp7NylXspEMeta?.alias || [],
    redirect: depositsYp7NylXspEMeta?.redirect || undefined,
    component: () => import("/home/ubuntu/coinmaster-frontend/pages/deposits.vue").then(m => m.default || m)
  },
  {
    name: earn_45moreAsQzqApGwlMeta?.name ?? "earn-more",
    path: earn_45moreAsQzqApGwlMeta?.path ?? "/earn-more",
    meta: earn_45moreAsQzqApGwlMeta || {},
    alias: earn_45moreAsQzqApGwlMeta?.alias || [],
    redirect: earn_45moreAsQzqApGwlMeta?.redirect || undefined,
    component: () => import("/home/ubuntu/coinmaster-frontend/pages/earn-more.vue").then(m => m.default || m)
  },
  {
    name: _91code_93fm8eau0BsNMeta?.name ?? "email-confirmation-code",
    path: _91code_93fm8eau0BsNMeta?.path ?? "/email-confirmation/:code()",
    meta: _91code_93fm8eau0BsNMeta || {},
    alias: _91code_93fm8eau0BsNMeta?.alias || [],
    redirect: _91code_93fm8eau0BsNMeta?.redirect || undefined,
    component: () => import("/home/ubuntu/coinmaster-frontend/pages/email-confirmation/[code].vue").then(m => m.default || m)
  },
  {
    name: faq9jBXMzv4g4Meta?.name ?? "faq",
    path: faq9jBXMzv4g4Meta?.path ?? "/faq",
    meta: faq9jBXMzv4g4Meta || {},
    alias: faq9jBXMzv4g4Meta?.alias || [],
    redirect: faq9jBXMzv4g4Meta?.redirect || undefined,
    component: () => import("/home/ubuntu/coinmaster-frontend/pages/faq.vue").then(m => m.default || m)
  },
  {
    name: forgot_45passwordmRJv7niOtsMeta?.name ?? "forgot-password",
    path: forgot_45passwordmRJv7niOtsMeta?.path ?? "/forgot-password",
    meta: forgot_45passwordmRJv7niOtsMeta || {},
    alias: forgot_45passwordmRJv7niOtsMeta?.alias || [],
    redirect: forgot_45passwordmRJv7niOtsMeta?.redirect || undefined,
    component: () => import("/home/ubuntu/coinmaster-frontend/pages/forgot-password.vue").then(m => m.default || m)
  },
  {
    name: free_45rollsJ8PmSMzuarMeta?.name ?? "free-rolls",
    path: free_45rollsJ8PmSMzuarMeta?.path ?? "/free-rolls",
    meta: free_45rollsJ8PmSMzuarMeta || {},
    alias: free_45rollsJ8PmSMzuarMeta?.alias || [],
    redirect: free_45rollsJ8PmSMzuarMeta?.redirect || undefined,
    component: () => import("/home/ubuntu/coinmaster-frontend/pages/free-rolls.vue").then(m => m.default || m)
  },
  {
    name: freeM7DUmcbR0sMeta?.name ?? "free",
    path: freeM7DUmcbR0sMeta?.path ?? "/free",
    meta: freeM7DUmcbR0sMeta || {},
    alias: freeM7DUmcbR0sMeta?.alias || [],
    redirect: freeM7DUmcbR0sMeta?.redirect || undefined,
    component: () => import("/home/ubuntu/coinmaster-frontend/pages/free.vue").then(m => m.default || m)
  },
  {
    path: games5lJZTEV8KPMeta?.path ?? "/games",
    children: [
  {
    name: _91category_939O5vOexN5hMeta?.name ?? "games-category",
    path: _91category_939O5vOexN5hMeta?.path ?? ":category()",
    meta: _91category_939O5vOexN5hMeta || {},
    alias: _91category_939O5vOexN5hMeta?.alias || [],
    redirect: _91category_939O5vOexN5hMeta?.redirect || undefined,
    component: () => import("/home/ubuntu/coinmaster-frontend/pages/games/[category].vue").then(m => m.default || m)
  },
  {
    name: _91game_939NQyuqzi6aMeta?.name ?? "games-provider-game",
    path: _91game_939NQyuqzi6aMeta?.path ?? ":provider()/:game()",
    meta: _91game_939NQyuqzi6aMeta || {},
    alias: _91game_939NQyuqzi6aMeta?.alias || [],
    redirect: _91game_939NQyuqzi6aMeta?.redirect || undefined,
    component: () => import("/home/ubuntu/coinmaster-frontend/pages/games/[provider]/[game].vue").then(m => m.default || m)
  },
  {
    name: indexbLfN9g9uLyMeta?.name ?? "games",
    path: indexbLfN9g9uLyMeta?.path ?? "",
    meta: indexbLfN9g9uLyMeta || {},
    alias: indexbLfN9g9uLyMeta?.alias || [],
    redirect: indexbLfN9g9uLyMeta?.redirect || undefined,
    component: () => import("/home/ubuntu/coinmaster-frontend/pages/games/index.vue").then(m => m.default || m)
  }
],
    name: games5lJZTEV8KPMeta?.name ?? undefined,
    meta: games5lJZTEV8KPMeta || {},
    alias: games5lJZTEV8KPMeta?.alias || [],
    redirect: games5lJZTEV8KPMeta?.redirect || undefined,
    component: () => import("/home/ubuntu/coinmaster-frontend/pages/games.vue").then(m => m.default || m)
  },
  {
    name: gdpremGz2s8OJOMeta?.name ?? "gdpr",
    path: gdpremGz2s8OJOMeta?.path ?? "/gdpr",
    meta: gdpremGz2s8OJOMeta || {},
    alias: gdpremGz2s8OJOMeta?.alias || [],
    redirect: gdpremGz2s8OJOMeta?.redirect || undefined,
    component: () => import("/home/ubuntu/coinmaster-frontend/pages/gdpr.vue").then(m => m.default || m)
  },
  {
    name: indexB2Pk4k558QMeta?.name ?? "index",
    path: indexB2Pk4k558QMeta?.path ?? "/",
    meta: indexB2Pk4k558QMeta || {},
    alias: indexB2Pk4k558QMeta?.alias || [],
    redirect: indexB2Pk4k558QMeta?.redirect || undefined,
    component: () => import("/home/ubuntu/coinmaster-frontend/pages/index.vue").then(m => m.default || m)
  },
  {
    name: loginCbPcqba93sMeta?.name ?? "login",
    path: loginCbPcqba93sMeta?.path ?? "/login",
    meta: loginCbPcqba93sMeta || {},
    alias: loginCbPcqba93sMeta?.alias || [],
    redirect: loginCbPcqba93sMeta?.redirect || undefined,
    component: () => import("/home/ubuntu/coinmaster-frontend/pages/login.vue").then(m => m.default || m)
  },
  {
    name: _91id_93LlsHA9WHtPMeta?.name ?? "messages-id",
    path: _91id_93LlsHA9WHtPMeta?.path ?? "/messages/:id()",
    meta: _91id_93LlsHA9WHtPMeta || {},
    alias: _91id_93LlsHA9WHtPMeta?.alias || [],
    redirect: _91id_93LlsHA9WHtPMeta?.redirect || undefined,
    component: () => import("/home/ubuntu/coinmaster-frontend/pages/messages/[id].vue").then(m => m.default || m)
  },
  {
    name: indexYgt1aOeB3YMeta?.name ?? "messages",
    path: indexYgt1aOeB3YMeta?.path ?? "/messages",
    meta: indexYgt1aOeB3YMeta || {},
    alias: indexYgt1aOeB3YMeta?.alias || [],
    redirect: indexYgt1aOeB3YMeta?.redirect || undefined,
    component: () => import("/home/ubuntu/coinmaster-frontend/pages/messages/index.vue").then(m => m.default || m)
  },
  {
    name: _91hash_93n5N7XB6l77Meta?.name ?? "promotion-hash",
    path: _91hash_93n5N7XB6l77Meta?.path ?? "/promotion/:hash()",
    meta: _91hash_93n5N7XB6l77Meta || {},
    alias: _91hash_93n5N7XB6l77Meta?.alias || [],
    redirect: _91hash_93n5N7XB6l77Meta?.redirect || undefined,
    component: () => import("/home/ubuntu/coinmaster-frontend/pages/promotion/[hash].vue").then(m => m.default || m)
  },
  {
    name: redeem_45tokensTIecFTZ31mMeta?.name ?? "redeem-tokens",
    path: redeem_45tokensTIecFTZ31mMeta?.path ?? "/redeem-tokens",
    meta: redeem_45tokensTIecFTZ31mMeta || {},
    alias: redeem_45tokensTIecFTZ31mMeta?.alias || [],
    redirect: redeem_45tokensTIecFTZ31mMeta?.redirect || undefined,
    component: () => import("/home/ubuntu/coinmaster-frontend/pages/redeem-tokens.vue").then(m => m.default || m)
  },
  {
    name: referral_45programhhp0dp3cqUMeta?.name ?? "referral-program",
    path: referral_45programhhp0dp3cqUMeta?.path ?? "/referral-program",
    meta: referral_45programhhp0dp3cqUMeta || {},
    alias: referral_45programhhp0dp3cqUMeta?.alias || [],
    redirect: referral_45programhhp0dp3cqUMeta?.redirect || undefined,
    component: () => import("/home/ubuntu/coinmaster-frontend/pages/referral-program.vue").then(m => m.default || m)
  },
  {
    name: settingspy2GqHyX7TMeta?.name ?? "settings",
    path: settingspy2GqHyX7TMeta?.path ?? "/settings",
    meta: settingspy2GqHyX7TMeta || {},
    alias: settingspy2GqHyX7TMeta?.alias || [],
    redirect: settingspy2GqHyX7TMeta?.redirect || undefined,
    component: () => import("/home/ubuntu/coinmaster-frontend/pages/settings.vue").then(m => m.default || m)
  },
  {
    name: statsXa0BkJAeDqMeta?.name ?? "stats",
    path: statsXa0BkJAeDqMeta?.path ?? "/stats",
    meta: statsXa0BkJAeDqMeta || {},
    alias: statsXa0BkJAeDqMeta?.alias || [],
    redirect: statsXa0BkJAeDqMeta?.redirect || undefined,
    component: () => import("/home/ubuntu/coinmaster-frontend/pages/stats.vue").then(m => m.default || m)
  },
  {
    name: withdraw_45nowOmGzW8z722Meta?.name ?? "withdraw-now",
    path: withdraw_45nowOmGzW8z722Meta?.path ?? "/withdraw-now",
    meta: withdraw_45nowOmGzW8z722Meta || {},
    alias: withdraw_45nowOmGzW8z722Meta?.alias || [],
    redirect: withdraw_45nowOmGzW8z722Meta?.redirect || undefined,
    component: () => import("/home/ubuntu/coinmaster-frontend/pages/withdraw-now.vue").then(m => m.default || m)
  },
  {
    name: withdrawalsVogH0Vb3ZSMeta?.name ?? "withdrawals",
    path: withdrawalsVogH0Vb3ZSMeta?.path ?? "/withdrawals",
    meta: withdrawalsVogH0Vb3ZSMeta || {},
    alias: withdrawalsVogH0Vb3ZSMeta?.alias || [],
    redirect: withdrawalsVogH0Vb3ZSMeta?.redirect || undefined,
    component: () => import("/home/ubuntu/coinmaster-frontend/pages/withdrawals.vue").then(m => m.default || m)
  }
]